<template>
  <div class="auth" style="background-image: linear-gradient(to bottom right, #5e5f5e, #f9ece3);">
    <div class="d-flex flex-column min-vh-100">
      <b-modal
        ref="my-loading"
        id="modal-2"
        title="Loading"
        @hide="onHide"
        centered
        hide-footer
        hide-header
      >
        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <p></p>
          <strong>Loading....</strong>
        </div>
      </b-modal>

      <div class="wrapper flex-grow-1"></div>
    </div>
  </div>
</template>
<script>
//import router from '../router'
import { mapMutations } from "vuex";
import router from '../router'
export default {
  name: "LineJoin",
  data() {
    return {
      val: {
        v1:'',
        v2:'',
        v3:'',
        v4:'',
        v5:'',        
      },
    };
  },
  methods: {
    ...mapMutations("account", ["setpage", "setParameter"]),
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        this.handleBackdrop();
      }
    },
    setorgpage(obj) {
      return new Promise(resolve => {
        resolve(this.setpage(obj));
      });
    }
  },
  async mounted() {
      //"/lineJoin/0/"+payload.Sub+"/"+payload.Picture+"/"+payload.Name+"/"+inState
      //'/lineJoin/:v1/:v2/:v3/:v4/:v5'
    this.val = {
        v1:this.$route.params.v1,
        v2:this.$route.params.v2,
        v3:this.$route.params.v3,
        v4:this.$route.params.v4,
        v5:this.$route.params.v5
    }
    let obj = { id: 'Home' };
    await this.setpage(obj)  
    await this.setParameter(this.val)      
    this.$refs["my-loading"].show();
    let ms =setTimeout(() => {   
              router.replace({ name: 'Edit'}).catch(err=>{err})
              clearInterval(ms);
          }, 500);      

  }
};
</script>
